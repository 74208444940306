<template>
  <div id="app">
    <header>
      <div class="nav-bar">
        <div class="nav-item" @click="modalOpen()">作った人</div>
      </div>
    </header>
    <div class="content-wrapper">
      <div class="title">
        <div class="title-item">じょうり・</div>
        <div class="title-item">けんきうしつ・</div>
        <div class="title-item">はいぞく・</div>
        <div class="title-item">かうんたあ</div>
      </div>
      <div class="prefix" v-show="!isResultAnnounced">
        配属まで&nbsp;残り
      </div>
      <div class="counter-wrapper" v-show="!isResultAnnounced">
        <div class="counter" v-show="days > 0">
          <Number :number="getDays(1)"></Number><Number :number="getDays(0)"></Number>日
        </div>
        <div class="counter">
          <Number :number="getHours(1)"></Number><Number :number="getHours(0)"></Number>時間
        </div>
        <div class="counter">
          <Number :number="getMinutes(1)"></Number><Number :number="getMinutes(0)"></Number>分
        </div>
        <div class="counter" v-show="days <= 0">
          <Number :number="getSeconds(1)"></Number><Number :number="getSeconds(0)"></Number>秒
        </div>
      </div>
      <div class="result-announce" v-if="isResultAnnounced">
        <img class="img-result-announce" src="./static/result_announce.png" />
      </div>
      <div class="post-twitter">
        <a :href="getTweet()" target="_blank">
          <img class="img-twitter" alt="twitter" src="./static/twitter.png" />
        </a>
      </div>
    </div>
    <div class="info">利用状況の計測のためにGoogle Analyticsを利用しています。</div>
    <footer>
      <div class="credit">&copy; 2019&nbsp;TRPG7</div>
    </footer>
    <div class="modal" v-if="isModalOpened">
      <div class="modal-background" @click="modalClose()"></div>
      <div class="modal-content">
        <div class="modal-title">Author</div>
        <div class="author">
          <img class="img-yukiho" src="./static/yukiho.png" />
          Yukiho&nbsp;Yoshieda
        </div>
        <a class="link-close" @click="modalClose()">Close</a>
      </div>
    </div>
    <div class="dummy">0123456789</div>
  </div>
</template>

<script>
import 'normalize.css';
import moment from 'moment';
import queryString from 'query-string';
import { setInterval } from 'timers';
import Number from './components/Number.vue';

export default {
  name: 'app',
  components: {
    Number,
  },
  data() {
    return {
      days: Number,
      hours: Number,
      minutes: Number,
      seconds: Number,
      isCreditOpened: false,
      isResultAnnounced: false,
      isModalOpened: false,
    };
  },
  methods: {
    getDays(digit) {
      if (digit === 1) {
        return Math.floor(this.days / 10);
      }
      return this.days % 10;
    },
    getHours(digit) {
      if (digit === 1) {
        return Math.floor(this.hours / 10);
      }
      return this.hours % 10;
    },
    getMinutes(digit) {
      if (digit === 1) {
        return Math.floor(this.minutes / 10);
      }
      return this.minutes % 10;
    },
    getSeconds(digit) {
      if (digit === 1) {
        return Math.floor(this.seconds / 10);
      }
      return this.seconds % 10;
    },
    getTweet() {
      const baseUrl = 'https://twitter.com/share';

      const query = {};
      query.url = 'https://jkhc.trpg7.site';
      query.hashtags = 'じょうり・けんきうしつ・はいぞく・かうんたあ';

      if (this.isResultAnnounced) {
        query.text = '結果発表されました\n';
      } else {
        query.text = `配属まで残り${this.days}日と${this.hours}時間${this.minutes}分${this.seconds}秒です\n`;
      }

      const url = `${baseUrl}?${queryString.stringify(query)}`;

      return url;
    },
    modalOpen() {
      this.isModalOpened = true;
    },
    modalClose() {
      this.isModalOpened = false;
    },
    getCurrentTime() {
      const diff = moment('2021-07-08 13:00').diff(moment());

      if (diff < 0) {
        this.isResultAnnounced = true;
        return;
      }

      const duration = moment.duration(diff);

      this.days = Math.floor(duration.asDays());
      this.hours = duration.hours();
      this.minutes = duration.minutes();
      this.seconds = duration.seconds();
    },
  },
  mounted() {
    this.getCurrentTime();
    setInterval(() => {
      this.getCurrentTime();
    }, 1000);
  },
};
</script>

<style>
header {
  height: 50px;
  background: rgba(0, 0, 0, 0.8);
}

.nav-bar {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-item {
  color: white;
  padding: 10px 30px;
}

.title {
  padding: 50px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.title-item {
  font-size: 50px;
}

.prefix {
  font-size: 70px;
  text-align: left;
  padding: 0px 10px;
}

.counter-wrapper {
  padding: 50px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.counter {
  font-size: 80px;
}

.number {
  font-size: 120px;
}

.content-wrapper {
  min-height: calc(100vh - 110px);
}

.post-twitter {
  margin: 20px;
}

.img-twitter {
  width: 50px;
}

.result-announce {
  text-align: center;
}

.img-result-announce {
  width: 80vw;
}

#app {
  font-family: "Pretty Momo";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

.info {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  height: 30px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.credit {
  color: white;
}

.modal {
  font-family: 'Archivo Black', sans-serif;
}

.modal-background {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .6);
}

.modal-content {
  position: fixed;
  background: white;
  width: 40vw;
  height: 40vh;
  top: 30vh;
  left: 30vw;
  z-index: 200;
  border-radius: 5px;
}

.modal-title {
  margin: 10px;
  font-size: 2rem;
  text-align: left;
}

.author {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.img-yukiho {
  width: 100px;
  height: 100px;
}

.link-close {
  font-size: 1.5rem;
}

.dummy {
  display: none;
}

@media screen and (max-width: 480px) {
  .title {
    padding: 1rem 0px;
    display: block;
  }

  .title-item {
    font-size: 1.8rem;
  }

  .prefix {
    font-size: 2rem;
  }

  .content-wrapper {
    min-height: calc(100vh - 5.5rem);
  }

  .counter-wrapper {
    padding: 1.5rem 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .counter {
    font-size: 3rem;
  }

  .number {
    font-size: 4.5rem;
  }

  .post-twitter {
    margin: 0.5rem;
  }

  .modal-content {
  width: 60vw;
  height: 40vh;
  top: 30vh;
  left: 20vw;
}

  .modal-title {
    font-size: 1.2rem;
  }

  .link-close {
    font-size: 1.1rem;
  }
}
</style>
