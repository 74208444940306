<template>
  <span class="number">
    <span v-if="number === 0">0</span>
    <span v-if="number === 1">1</span>
    <span v-if="number === 2">2</span>
    <span v-if="number === 3">3</span>
    <span v-if="number === 4">4</span>
    <span v-if="number === 5">5</span>
    <span v-if="number === 6">6</span>
    <span v-if="number === 7">7</span>
    <span v-if="number === 8">8</span>
    <span v-if="number === 9">9</span>
  </span>
</template>

<script>
export default {
  name: 'Number',
  props: {
    number: Number,
  },
};
</script>
